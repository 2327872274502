<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :ref="modal_name">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <v-toolbar class="draggable_selector" slot="header" dark color="primary" dense flat>
          <v-btn icon @click="closeModal(modal_name)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ page.title }}</v-toolbar-title>
          <v-spacer/>
        </v-toolbar>
        <div slot="content">
          <v-divider/>
          <v-card-text>
            <v-text-field :disabled="record.token" class="mt-5" :label="$store.getters.translate('name')" v-model="record.name"/>
            <base-row v-if="record.token" :label="$store.getters.translate('token')" :value="record.token">
            </base-row>
            <v-alert v-if="record.token" dense outlined type="success">
              {{ $store.getters.translate("token_created") }}
            </v-alert>
          </v-card-text>
        </div>
        <v-card-footer slot="footer">
          <v-btn v-if="!record.token" :disabled="!record.name" @click="save()" large block>
            {{ $store.getters.translate("create_token") }}
          </v-btn>
        </v-card-footer>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../../plugins/helpFunctions";
import BaseModal from "../../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../../components/commonComponents/BaseDraggableModal";

export default {
  props: ["modal_name"],
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("tokens"),
        name: "tokens",
      },
      record: {},
      loading: false,
      user_id: null,
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    load(user_id) {
      this.record = {};
      this.user_id = user_id;
      this.openModal(this.modal_name);
    },
    save() {
      this.loading = true;
      this.$http
        .post(this.$store.getters.appUrl + "v2/users/" + this.user_id + "/tokens", this.record)
        .then((response) => {
          this.loading = false;
          this.record.token = response.data;
          this.$emit("refresh");
        })
        .catch((error) => {
          this.$toasted.error(error);
          this.loading = false;
        });
    },
  },
};
</script>