<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :ref="modal_name">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <v-toolbar class="draggable_selector" slot="header" dark color="primary" dense flat>
          <v-btn icon @click="closeModal(modal_name)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ page.title }}</v-toolbar-title>
          <v-spacer/>
          <v-btn @click="deleteToken()" text small>
            <v-icon>mdi-content-trash-can</v-icon>
          </v-btn>
        </v-toolbar>
        <div slot="content">
          <v-divider/>
          <v-card-text>
            <v-text-field disabled class="mt-5" :label="$store.getters.translate('name')" v-model="record.name"/>
          </v-card-text>
        </div>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../../plugins/helpFunctions";
import BaseModal from "../../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../../components/commonComponents/BaseDraggableModal";

export default {
  props: ["modal_name"],
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("tokens"),
        name: "tokens",
        model: "token"
      },
      record: {},
      loading: false,
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    load(item) {
      this.record = item;
      this.openModal(this.modal_name);
    },
    deleteToken() {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then((res) => {
        if (res) {
          this.loading = true;
          this.$http
            .delete(this.$store.getters.appUrl + "v2/users/" + this.user_id + "/tokens/" + this.record.id)
            .then(() => {
              this.loading = false;
              this.$toasted.success(this.$store.getters.translate("success"));
              this.$emit("refresh");
              this.closeModal(this.modal_name);
            })
            .catch((error) => {
              this.$toasted.error(error);
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>